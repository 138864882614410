import React from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from './Navbar';
import { Main } from './styles';

const HomeLayout = () => (
  <>
    <Main>
      <Outlet />
    </Main>
    <Navbar />
  </>
);

export default HomeLayout;
