import axiosDef from 'axios';
import { omit } from 'ramda';

import { TOKEN, DEFAULT_ERROR } from './consts';

export { TOKEN };

const storage = window[process.env.REACT_APP_TOKEN_STORAGE];

export const axios = axiosDef.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    ...(storage[TOKEN] ? { Authorization: `Bearer ${storage[TOKEN]}` } : {}),
  },
  timeout: 60000,
  validateStatus: (status) => (status >= 200 && status < 300) || status === 400 || status === 401 || status === 403,
});

export const updateToken = (token) => {
  if (token) {
    storage.setItem(TOKEN, token);
    axios.defaults.headers = {
      ...axios.defaults.headers,
      Authorization: `Bearer ${token}`,
    };

    return;
  }

  storage.removeItem(TOKEN);
  axios.defaults.headers = omit(['Authorization'], axios.defaults.headers);
};

const getFilename = (headers) => headers['content-disposition'].replace(/^.+filename=/i, '').replace(/\..+$/i, '') || 'none';

const handleResponse = ({ status, data, config, headers }) => {
  if (status === 401) {
    updateToken();
    window.location.reload();
    throw new Error('Unauthorized');
  }
  if (status < 200 || status >= 300) {
    const error = new Error(data?.error || DEFAULT_ERROR);
    error.status = status;
    throw error;
  }

  return config.responseType === 'blob' ? { name: getFilename(headers), blob: data } : data;
};

const handler = async (promise, ...args) => {
  try {
    return handleResponse(await promise(...args));
  } catch (e) {
    if (e?.status) throw Error(e?.message);

    throw Error('Oops, something went wrong. Please contact us via technology@medignition.com.');
  }
};

const methods = {
  get: (...args) => handler(axios.get, ...args),
  post: (...args) => handler(axios.post, ...args),
  put: (...args) => handler(axios.put, ...args),
  patch: (...args) => handler(axios.patch, ...args),
  delete: (...args) => handler(axios.delete, ...args),
};

export default methods;
