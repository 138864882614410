import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

export const Container = styled(motion.nav).attrs(({ $isOpened }) => ({
  animate: { width: $isOpened ? 'auto' : 44 },
  transition: { duration: 0.5 },
  initial: false,
}))`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  position: fixed;
  inset: 0 auto 0 0;
  width: auto;
  max-height: 100%;
  background-color: var(--color-white);
  padding: 15px 0;
  box-shadow: var(--shadow);
  z-index: 100;
  overflow: hidden;
`;

export const NavLink = styled(motion(Link)).attrs(({ $isMenuOpened }) => ({
  animate: { padding: $isMenuOpened ? '0 20px' : '0 5px' },
  transition: { duration: 0.4 },
  initial: false,
}))`
  width: max-content;
  padding: 0 5px;
`;

export const Content = styled(motion.div).attrs(({ $isMenuOpened }) => ({
  animate: { paddingLeft: $isMenuOpened ? 20 : 11 },
  transition: { duration: 0.4 },
  initial: false,
}))`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: max-content;
  min-width: 100%;
  overflow: auto;
  padding-left: 11px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  column-gap: 9px;
  height: 44px;
  min-height: 44px;
  width: 100%;
  border-radius: 5px 0 0 5px;
  border-right: 5px solid;
  border-color: ${({ $isActive }) => ($isActive ? 'var(--color-blue80)' : 'transparent')};
  background-color: ${({ $isActive }) => ($isActive ? 'var(--color-gray10)' : 'none')};
  font: var(--font14M);
  color: ${({ $isActive }) => ($isActive ? 'var(--color-blue80)' : 'var(--color-text100)')};
  padding-right: 10px;
  &:disabled {
    border-color: var(--color-blue80);
    background-color: var(--color-gray10);
    color: var(--color-blue80);
  }
  &:before {
    content: '';
    width: 24px;
    min-width: 24px;
    height: 24px;
    background-image: url(${({ $icon }) => $icon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media (hover) {
    &:hover:enabled {
      border-color: var(--color-blue80);
      background-color: var(--color-gray10);
    }
  }
`;

export const Wrapper = styled(motion.div).attrs(({ $isOpened }) => ({
  animate: { height: $isOpened ? 'auto' : 0, overflow: $isOpened ? 'visible' : 'hidden' },
  transition: {
    height: { duration: 0.3 },
    overflow: { delay: $isOpened ? 0.3 : 0 },
  },
  initial: false,
}))``;

export const Item = styled(Button)`
  color: var(${({ $isCompleted }) => ($isCompleted ? '--color-green' : '--color-gray70')});
  &:before {
    content: none;
  }
  & > span {
    font: inherit;
    color: inherit;
  }
`;

export const Footer = styled(motion.p).attrs(({ $isMenuOpened }) => ({
  animate: {
    padding: $isMenuOpened ? '0 20px' : '0 5px',
    textAlign: $isMenuOpened ? 'left' : 'center',
  },
  transition: { duration: 0.4 },
  initial: false,
}))`
  column-gap: 10px;
  width: max-content;
  min-width: 44px;
  font: var(--font16B);
  color: var(--color-text100);
  text-align: center;
  padding: 0 5px;
`;
