import styled from 'styled-components';

export const Main = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 915px;
  max-height: 100%;
  padding: 100px 15px 30px 60px;
  margin: 0 auto;
`;
