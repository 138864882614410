import styled from 'styled-components';

import image from 'assets/images/skyBackground.jpg';
import SelectDef from 'components/Select';

export const Container = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  background-image: url(${image});
  background-position: 615px center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 991px), (orientation: portrait) {
    align-items: center;
    background: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 615px;
  padding: 25px 90px 0;
`;

export const Select = styled(SelectDef)`
  width: 55px;
  & > select {
    border: none;
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 615px;
  padding: 45px 90px;
`;
