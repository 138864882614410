import React, { useCallback } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from 'assets/svg-icons/logo.svg';

import { LANGUAGES } from './consts';
import { Container, Wrapper, Select, Content } from './styles';

const SkyLayout = () => {
  const { t, i18n } = useTranslation('common');
  const onSelect = useCallback((lng) => i18n.changeLanguage(lng), [i18n]);

  return (
    <Container>
      <Wrapper>
        <Link to="/">
          <Logo />
        </Link>
        <Select options={LANGUAGES} value={i18n.language} onSelect={onSelect} placeholder={t('Select language')} />
      </Wrapper>
      <Content>
        <Outlet />
      </Content>
    </Container>
  );
};

export default SkyLayout;
