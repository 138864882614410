import { takeLeading, debounce, takeEvery, call, put, throttle, select } from 'redux-saga/effects';
import { any, pick, omit, propEq, props, isNotEmpty, pipe, path } from 'ramda';
import { Node } from 'slate';

import { withAlert } from 'store/alerts';
import api from 'api';
import writingStatic from 'locale/en/writing.json';

import {
  FETCH_WRITINGS,
  FETCH_WRITING,
  CREATE_WRITING,
  SAVE_WRITING,
  REMOVE_WRITING,
  DOWNLOAD_WRITING_DOCX,
  AI_WRITERS,
  SAVE_W_QUESTION,
  SAVE_CHAPTER,
  COPY_WH_QUESTIONS,
} from './types';
import { updatePagination, updateWriting } from './actions';
import { getWriting, getWritingProp } from './selectors';
import { NAME, SW_CREATE, COUNT, HAS_MORE, NEXT, ID, W_QUESTIONS, ITEM_ID, CONTEXT, TEXT, FORMATTED_TEXT, CHAPTERS } from '.';

function* fetchWritings({ payload }) {
  const data = yield call(api.get, '/writings', { params: payload });

  yield put(updatePagination(pick([COUNT, HAS_MORE, NEXT], data)));

  return { success: data?.data || [] };
}

function* fetchWriting({ payload }) {
  const data = yield call(api.get, `/writings/${payload}`);

  yield put(updateWriting(data));
}

function* createWriting({ payload }) {
  const { [ID]: id } = yield call(api.post, '/writings', { [NAME]: SW_CREATE, ...payload });

  return { success: id };
}

function* saveWriting({ payload }) {
  const data = yield call(api.patch, `/writings/${payload[ID]}`, omit([ID], payload));

  yield put(updateWriting(data));
}

function* saveWQuestion({ payload }) {
  const writing = yield select(getWriting);
  const wQuestions = writing[W_QUESTIONS] ? [...writing[W_QUESTIONS]] : [];
  const index = (writing[W_QUESTIONS] || []).findIndex(propEq(payload.id, ITEM_ID));
  const data = {
    [ITEM_ID]: payload.id,
    [TEXT]: payload.value.map((n) => Node.string(n)).join('\n'),
    [FORMATTED_TEXT]: JSON.stringify(payload.value),
  };

  if (index >= 0) {
    wQuestions[index] = { ...wQuestions[index], ...data };
  } else {
    wQuestions.push(data);
  }

  yield saveWriting({ payload: { [ID]: writing[ID], [W_QUESTIONS]: wQuestions } });
}

function* saveChapter({ payload }) {
  const writing = yield select(getWriting);
  const chapters = writing[CHAPTERS] ? [...writing[CHAPTERS]] : [];
  const index = (writing[CHAPTERS] || []).findIndex(propEq(payload.id, ITEM_ID));
  const data = {
    [ITEM_ID]: payload.id,
    [NAME]: payload.name,
    [TEXT]: payload.value.map((n) => Node.string(n)).join('\n'),
    [FORMATTED_TEXT]: JSON.stringify(payload.value),
  };

  if (index >= 0) {
    chapters[index] = { ...chapters[index], ...data };
  } else {
    chapters.push(data);
  }

  yield saveWriting({ payload: { [ID]: writing[ID], [CHAPTERS]: chapters } });
}

function* removeWriting({ payload }) {
  return { success: yield call(api.delete, `/writings/${payload}`) };
}

function* downloadWritingDocx({ payload }) {
  const { name, blob } = yield call(api.post, `/writings/${payload}/downloads`, {}, { responseType: 'blob' });
  const blobUrl = window.URL.createObjectURL(
    new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
  );

  return { success: { name, blobUrl } };
}

function* aiWriters({ payload }) {
  const writingId = yield select(getWritingProp(ID));

  return { success: yield call(api.post, `writings/${writingId}/aiwriters`, { [CONTEXT]: payload }) };
}

const applyPresets = (preset, wQuestionsObject) => {
  const nodes = preset.reduce((acc, key) => {
    const wqNodes = JSON.parse(wQuestionsObject[key][FORMATTED_TEXT]);

    if (wqNodes.length) {
      wqNodes.forEach((node) => {
        acc.push(node);
      });
      acc.push({ type: 'paragraph', children: [{ text: '' }] });
    }

    return acc;
  }, []);

  return {
    [TEXT]: nodes.map((n) => Node.string(n)).join('\n'),
    [FORMATTED_TEXT]: JSON.stringify(nodes?.length ? nodes : ''),
  };
};
function* copyWhQuestions() {
  const writing = yield select(getWriting);
  const [writingId, wQuestions, chapters] = props([ID, W_QUESTIONS, CHAPTERS], writing);
  const chaptersObject = chapters.reduce((acc, item) => ({ ...acc, [item[ITEM_ID]]: item }), {});
  const checkIsHaveContent = (id) => pipe(path([id, TEXT]), isNotEmpty)(chaptersObject);
  const isCopyRequired = any(
    pipe(props(['id', 'preset']), ([i, p]) => Boolean(p?.length) && !checkIsHaveContent(i)),
    writingStatic.$chapters
  );

  if (!isCopyRequired) return { success: true };

  const wQuestionsObject = wQuestions.reduce((acc, item) => ({ ...acc, [item[ITEM_ID]]: item }), {});
  const result = writingStatic.$chapters
    .map(({ id, preset }) => {
      if (!preset || checkIsHaveContent(id)) return chaptersObject[id];

      return { ...chaptersObject[id], ...applyPresets(preset, wQuestionsObject) };
    })
    .filter(Boolean);

  yield saveWriting({ payload: { [ID]: writingId, [CHAPTERS]: result } });

  return { success: true };
}

export default function* watchWritings() {
  yield throttle(500, FETCH_WRITINGS, withAlert(fetchWritings));
  yield takeLeading(FETCH_WRITING, withAlert(fetchWriting));
  yield takeEvery(CREATE_WRITING, withAlert(createWriting));
  yield debounce(500, SAVE_W_QUESTION, withAlert(saveWQuestion));
  yield debounce(500, SAVE_CHAPTER, withAlert(saveChapter));
  yield takeLeading(SAVE_WRITING, withAlert(saveWriting));
  yield takeLeading(REMOVE_WRITING, withAlert(removeWriting));
  yield takeLeading(DOWNLOAD_WRITING_DOCX, withAlert(downloadWritingDocx));
  yield takeLeading(AI_WRITERS, withAlert(aiWriters));
  yield takeLeading(COPY_WH_QUESTIONS, withAlert(copyWhQuestions));
}
